import { VegaBox } from '@heartlandone/vega-react';

import { LayoutTypes } from 'types/app';

import styles from './layoutCard.module.css';

interface Props {
  children: any;
  type?: LayoutTypes;
  noPadding?: boolean;
  id?: string;
  flexLength?: true;
  className?: string;
}

const LayoutCard: React.FC<Props> = ({
  children,
  type = LayoutTypes.Card,
  noPadding = false,
  id,
  flexLength = false,
  className = '',
}) => {
  const classes = `
    ${type === LayoutTypes.Card ? styles.layoutCard : styles.layoutCardTransparent} 
    ${noPadding ? styles.noPadding : ''}
    ${flexLength ? styles.flexLength : ''}
    ${className}
  `;

  return (
    <VegaBox id={id} className={classes} backgroundColor={type === LayoutTypes.Card ? 'bg-primary' : 'bg-transparent'}>
      {children}
    </VegaBox>
  );
};

export default LayoutCard;

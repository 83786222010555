import React from 'react';

import { VegaBox, VegaButton, VegaCard, VegaFlex, VegaFont } from '@heartlandone/vega-react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import Image from 'next/image';
import { vegaMuiTheme, VegaMuiThemeTypes } from 'theme/theme';

import LayoutCard from 'components/layout/layoutCard';
import HeartlandLogo from 'components/layout/logo';
import LoginSplash from 'components/loginViews/loginSplash';

import styles from './loginViews.module.css';

const ThemeWrapper = ({
  children,
  defaultTheme,
  noWrap,
}: {
  children: any;
  defaultTheme: boolean;
  noWrap?: boolean;
}) => {
  if (defaultTheme)
    return (
      <ThemeProvider theme={createTheme(vegaMuiTheme(VegaMuiThemeTypes.Light, alpha, {}))}>
        {noWrap ? children : <Box className={styles.wrapperContainer}>{children}</Box>}
      </ThemeProvider>
    );
  return <Box className={styles.wrapperContainer}>{children}</Box>;
};

export const AuthError = ({ action, defaultTheme }: { action: () => void; defaultTheme: boolean }) => {
  return (
    <ThemeWrapper defaultTheme={defaultTheme}>
      <VegaBox className={styles.container}>
        <HeartlandLogo height={40} width={135} />
        <LayoutCard>
          <VegaCard padding={'size-24'}>
            <VegaFlex direction={'col'} justifyContent={'start'} alignItems={'start'} gap="size-20">
              <VegaFont variant={'font-h4'}>Unable to authenticate user</VegaFont>
              <VegaFont>We have logged this error, and will work to resolve it as soon as possible.</VegaFont>
              <VegaButton variant="primary" label="Login" data-testid="btn-login" onVegaClick={action} />
            </VegaFlex>
          </VegaCard>
        </LayoutCard>
      </VegaBox>
    </ThemeWrapper>
  );
};

export const MaintenanceModeError = ({ message, defaultTheme }: { message: string; defaultTheme: boolean }) => {
  return (
    <ThemeWrapper defaultTheme={defaultTheme}>
      <Box className={styles.container}>
        <HeartlandLogo height={40} width={135} />
        <LayoutCard>
          <Box className={styles.flexReverse}>
            <Box className={styles.box}>
              <Box className={styles.maintenanceHeader} color="text.primary">
                System is temporarily unavailable
              </Box>
              {!isEmpty(message) && (
                <ThemeProvider theme={createTheme()}>
                  <Alert sx={{ fontFamily: 'inherit' }} severity="warning">
                    {message}
                  </Alert>
                </ThemeProvider>
              )}
              <Box color="text.secondary">
                We apologize for the inconvenience, but our system is temporarily unavailable. We are working on
                resolving the issue as soon as possible. Please try again later.
              </Box>
              <Box color="text.secondary">
                If you continue to have problems, please contact our{' '}
                <Link className={styles.link} href="mailto:support@heartland.us" target="_blank">
                  support team
                </Link>{' '}
                for assistance.
              </Box>
              <Box color="text.secondary">Thank you for your patience.</Box>
            </Box>
            <Box className={styles.maintenanceBoxImage}>
              <Image
                src={process.env.NEXT_PUBLIC_IMAGE_URL + '/myAccount/MaintenanceMode.png'}
                alt="Maintenance Mode"
                height={285}
                width={338}
                className={styles.maintenanceImage}
              />
            </Box>
          </Box>
        </LayoutCard>
      </Box>
    </ThemeWrapper>
  );
};

export const AppLoading = () => {
  return (
    <ThemeWrapper defaultTheme={true} noWrap>
      <LoginSplash />
    </ThemeWrapper>
  );
};

import React from 'react';
import { useEffect, useState } from 'react';

import { EventType } from '@azure/msal-browser';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FeatureFlag } from '@heartlandone/vega';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from 'next-i18next.config';

import { AppLoading, MaintenanceModeError } from 'components/loginViews';
import { msalInstance } from 'components/user/auth/authProvider';
// eslint-disable-next-line import/order
import Versioning from 'components/versioning';
import 'styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/regular.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';

const inter = Inter({
  display: 'swap',
  subsets: ['latin'],
});

//Disable Font Awesome from adding CSS since we did it manually above.
config.autoAddCss = false;
FeatureFlag.enable('VEGA_REACT.PREVENT_SSR');

const AppContainer = dynamic(() => import('../components/app'), { ssr: false, loading: () => <AppLoading /> });

import { LoadedVersionKey } from 'types/app';
import {
  DeleteAllNonAppKeysFromLocalStorage,
  InsertToLocalStorage,
  RetrieveFromLocalStorage,
} from 'utility/localStorage';

//PolyFill Window.Crypto.RandomUUID to prevent old browsers from erroring on MSAL 2.0
if (typeof window != 'undefined') {
  try {
    if (!('randomUUID' in window.crypto))
      crypto.randomUUID = function randomUUID() {
        // @ts-expect-error: Operator '+' cannot be applied to types 'number[]' and 'number'
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
        );
      };
  } catch (error) {
    console.error('Unable to polyfill crypto randomUUID.', error);
  }
}

// eslint-disable-next-line unicorn/prefer-top-level-await
msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const { account } = event.payload;
      msalInstance.setActiveAccount(account);
    }
  });
});

function MyApp({ Component, pageProps }: AppProps) {
  const [showApp, setShowApp] = useState(false);
  const [maintenance, setMaintenance] = useState(false);

  const router = useRouter();

  useEffect(() => {
    //Removing the media attribute from style elements allows dynamic JavaScript Bundles to be loaded without missing CSS
    const handleRouteChange = () => {
      const styleElements = document.querySelectorAll('style[media="x"]');
      for (const styleTag of styleElements) {
        styleTag.removeAttribute('media');
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeStart', handleRouteChange);
  }, [router]);

  const { myAccountMaintenanceMode, myAccountMaintenanceModeText } = useFlags();

  useEffect(() => {
    if (typeof window != 'undefined' && myAccountMaintenanceMode != null) {
      const cacheVersion = process.env.NEXT_PUBLIC_CACHE_VERSION ?? '0';
      const lastLoadedVersion = RetrieveFromLocalStorage(LoadedVersionKey) ?? 'abc';

      if (lastLoadedVersion != cacheVersion) {
        //remove active account
        msalInstance.setActiveAccount(null);
        //wipe out local storage
        DeleteAllNonAppKeysFromLocalStorage();
      }

      if (!myAccountMaintenanceMode) {
        setMaintenance(false);
      }

      InsertToLocalStorage(LoadedVersionKey, cacheVersion);

      if (myAccountMaintenanceMode && !showApp) {
        setMaintenance(true);
      } else {
        setShowApp(true);
      }
    }
  }, [myAccountMaintenanceMode, showApp]);

  let contents = null;

  if (showApp) contents = <AppContainer pageProps={pageProps} Component={Component} />;
  if (maintenance) contents = <MaintenanceModeError defaultTheme message={myAccountMaintenanceModeText} />;

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }

        body {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <Versioning />
      {contents}
    </>
  );
}

export default withLDProvider<any>({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_ID?.toString() ?? '',
})(appWithTranslation(MyApp, nextI18nextConfig));

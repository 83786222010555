import { useTheme } from '@mui/material/styles';
import toUpper from 'lodash/toUpper';
import Image from 'next/image';

import { OneXThemePalette } from 'types/theme';

import styles from './logo.module.css';

interface Props {
  height: number;
  width: number;
}

const HeartlandLogo: React.FC<Props> = ({ height, width }) => {
  const theme = useTheme<OneXThemePalette>();

  let logoStyle = styles.logo;
  logoStyle = toUpper(theme.palette.primary.bgPage) === '#F0F3F7' ? styles.logo : styles.logoInv;

  return (
    <Image
      alt="heartlandlogo"
      height={height}
      width={width}
      priority
      className={logoStyle}
      src={process.env.NEXT_PUBLIC_IMAGE_URL + '/heartlandLogoGray.svg'}
    />
  );
};

export default HeartlandLogo;

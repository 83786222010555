/** @type {import('next-i18next').UserConfig} */

//For this to work on the server, the NEXT_PUBLIC_ENV must be an environment variable set in the App Service.
const useFilteredLanguages =
  process.env.NEXT_PUBLIC_ENV === 'DEV' || // remove this line to test your translations in dev
  process.env.NEXT_PUBLIC_ENV === 'PRD';

const filteredLanguages = ['en'];
const allLanguages = ['en', 'fr'];

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: useFilteredLanguages ? filteredLanguages : allLanguages,
  },
  localePath: typeof window === 'undefined' ? require('path').resolve('./public/locales') : '/locales',
  reloadOnPrerender: process.env.NEXT_PUBLIC_ENV === 'DEV',
};

import { ChartDateKey, SideNavKey, ThemeKey } from 'types/app';

export const InsertToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const RetrieveFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);

  try {
    const cleanData = data ? JSON.parse(data) : null;
    return cleanData;
  } catch {
    //we will try to parse and clean the data but if it fails we will just return the raw data
    return data;
  }
};

export const DeleteFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const DeleteAllNonAppKeysFromLocalStorage = () => {
  const storageKeys = Object.keys(localStorage);
  let keyCount = storageKeys.length;
  while (keyCount--) {
    if (
      storageKeys[keyCount] != SideNavKey &&
      storageKeys[keyCount] != ThemeKey &&
      storageKeys[keyCount] != ChartDateKey
    ) {
      DeleteFromLocalStorage(storageKeys[keyCount]);
    }
  }
};
